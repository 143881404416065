<template>
    <v-app class="position-relative" style="width: 100vw; height: 98vh; overflow: hidden !important;">
        <div class="d-md-flex d-block justify-content-md-center align-self-md-end" style="width: 100%; margin-bottom: 3rem;">
            <div class="position-fixed top-0 left-0 w-100 h-100" style="z-index: 1; top: 0;">
                <div id="login-slider-wrapper">
                    <div id="promotion-login-slider">
                        <div>
                            <div class="image-slide position-fixed"
                                 :style="`background-image: url('/static/image/slider/5.jpg')`" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-fill flex-md-grow-0 d-flex align-content-center justify-content-center align-items-center position-relative"
                 style="z-index: 1041; background-image: url('/img/login_block_bg.svg'); background-size: 100% auto;">
                <component v-bind:is="mainComponent" @openComponentView="openComponentView" />
            </div>
        </div>
    </v-app>
</template>

<script>
    import baseComponent from "@/scripts/baseComponent";
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import "@/assets/admin-css/login.css";
    const queryString = require("query-string");
    export default {
        extends: baseComponent,
        props: {},
        data: () => ({
            mainComponent: lazyLoadComponent({
                componentFactory: () => import("@/views/auth/ComponentLogin"),
                loading: SkeletonBox
            })
        }),
        mounted() {
            (function(d, s, id) { var js, pjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "//www.tickcounter.com/static/js/loader.js"; pjs.parentNode.insertBefore(js, pjs); }(document, "script", "tickcounter-sdk"));
            
        },
        created() {
            if (this.$route.name === "ComponentRegister") {
                this.openComponentView("ComponentRegister");
            }
            if (this.$route.name === "ComponentResetPassword") {
                this.openComponentView(this.$route.name);
            }
        },
        methods: {
            openComponentView(componentName) {
                this.mainComponent = lazyLoadComponent({
                    componentFactory: () => import(`@/views/auth/${componentName}`),
                    loading: SkeletonBox
                });
                let urlParams = queryString.stringify(this.$route.query);
                if (componentName === "ComponentRegister")
                    window.history.pushState("", "", `/register?${urlParams}`);
                if (componentName === "ComponentResetPassword")
                    window.history.pushState("", "", "/forgot-password");
                if (componentName === "ComponentLogin")
                    window.history.pushState("", "", "/login");
            }
        },
        watch: {
            overlay(val) {
                val &&
                    setTimeout(() => {
                        this.overlay = false;
                    }, 3000);
            }
        }
    };
</script>

<style>
    .el-button.bg-gradient-orange:disabled,
    .btn.bg-gradient-orange:disabled {
        opacity: 0.5 !important;
        filter: grayscale();
    }

    .react-code-input > input {
        font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace !important;
    }
</style>